/** @jsx jsx */

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FC } from 'react'
import { jsx } from 'theme-ui'

import UniversalLink from '../components/universal-link'
import routes from '../constants/routes'

interface BlogPostTeaserProps {
  node: any
}

const BlogPostTeaser: FC<BlogPostTeaserProps> = ({ node, index, to }) => {
  const heroImage = getImage(node.heroImage)
  const authorImage = getImage(node?.author?.image)
  const isExternalPost = to.includes(`http`)
  let externalDomain = ``
  let href

  if (isExternalPost) {
    const { hostname } = new URL(to)
    externalDomain = hostname
    href = to
    to = undefined
  }

  return (
    <li key={node.id} sx={{ mt: [index ? 5 : 0, null, 0] }}>
      {node.tags?.map((tag, index) => (
        <UniversalLink
          key={tag}
          to={`${routes.contentCenter}/tags/${tag.toLowerCase().replace(/\ /g, `-`)}`}
          sx={{
            ml: index ? 3 : 0,
            display: `inline-block`,
            color: `primary`,
            textDecoration: `none`,
            fontWeight: 500,
            fontSize: 1,
            textTransform: `uppercase`,
            letterSpacing: `0.1em`,

            [`:hover, :focus`]: {
              textDecoration: `underline`,
            },
          }}
        >
          {tag}
        </UniversalLink>
      ))}

      <UniversalLink
        {...{ to, href }}
        sx={{
          color: `inherit`,
          textDecoration: `none`,
          display: `block`,
        }}
      >
        <div
          sx={{
            mt: 2,
            fontSize: 2,
            color: `gray6_slate3`,
            display: `flex`,
            alignItems: `center`,
            gap: 3,
          }}
        >
          {/* {node.publishDate} */}

          <div sx={{ display: `flex`, alignItems: `center`, gap: 1 }}>
            {authorImage && (
              <div
                sx={{
                  transform: `translateZ(0)`,
                  borderRadius: 999,
                  overflow: `hidden`,
                  backgroundImage: `var(--gradient)`,
                  size: 22,

                  [`&& img`]: {
                    my: 0,
                  },
                }}
              >
                <GatsbyImage image={authorImage} alt={node.author.name} />
              </div>
            )}

            <div>{node.author?.name && node.author.name}</div>
          </div>
        </div>

        <div
          sx={{
            aspectRatio: `16 / 9`,
            mt: 3,
            overflow: `hidden`,
            bg: `black`,
            backgroundImage: `var(--gradient)`,
            borderRadius: 6,
            boxShadow: `0 2px 8px -2px rgb(0 0 0 / 50%)`,
            transform: `translateZ(0)`,

            [`a:hover > &, a:focus > &`]: {
              boxShadow: `0 2px 32px rgb(0 0 0 / 25%)`,
            },
          }}
        >
          {heroImage && <GatsbyImage image={heroImage} alt="" sx={{ height: `100%`, objectFit: `cover` }} />}
        </div>

        {node.title && <h1 sx={{ mt: 3, mb: 0, fontSize: 4 }}>{node.title}</h1>}

        {node.description && (
          <div
            sx={{
              mt: 2,
              fontSize: `20px`,

              [`& > p`]: {
                my: 0,
              },
            }}
            dangerouslySetInnerHTML={{ __html: node.description.childMarkdownRemark.html }}
          />
        )}

        <div
          sx={{
            mt: 2,
            fontSize: 2,
            color: `primary`,

            [`a:hover > &, a:focus > &`]: {
              textDecoration: `underline`,
            },
          }}
        >
          Read more {isExternalPost ? `on ${externalDomain} »` : `»`}
        </div>
      </UniversalLink>
    </li>
  )
}

export default BlogPostTeaser
