/** @jsx jsx */

import { graphql } from 'gatsby'
import { FC, Fragment, useEffect, useRef } from 'react'
import { jsx } from 'theme-ui'

import BlogPostTeaser from '../../components/blog-post-teaser'
import BlogPostTeaserCard from '../../components/blog-post-teaser-card'
import Halo from '../../components/halo'
import Intro from '../../components/intro'
import Layout from '../../components/layout'
import SignUpCallToAction from '../../components/sign-up-call-to-action'
import UniversalLink from '../../components/universal-link'
import routes from '../../constants/routes'

interface ContentCenterProps {
  data: {
    allContentfulPageMetadata: {
      edges: {
        node: {
          title: string
          description: any
        }
      }
    }

    allContentfulTag: {
      edges: {
        id: string
        name: string
      }
    }

    allContentfulBlogPost: {
      edges: {
        id: string
        slug: string
        title: string
      }
    }

    allContentfulCaseStudy: {
      edges: {
        id: string
        slug: string
        title: string
      }
    }

    allContentfulExternalPost: {
      edges: {
        id: string
        link: string
        title: string
      }
    }
  }
}

const ContentCenter: FC<ContentCenterProps> = ({ data }) => {
  const tagsRef = useRef(null)

  useEffect(() => {
    let el

    tagsRef.current.querySelectorAll(`li > a`).forEach((tag) => {
      if (tag.innerText === `All`) el = tag
    })

    el.focus()
  }, [tagsRef])

  const blogPostEdges = data.allContentfulBlogPost.edges
  const caseStudyEdges = data.allContentfulCaseStudy.edges
  const tags = data.allContentfulTag.edges
  const externalPostEdges = data.allContentfulExternalPost.edges

  // TODO: Refactor and sort as a unified GraphQL query
  const items = [...blogPostEdges, ...caseStudyEdges, ...externalPostEdges]

  const sortedItems = items.sort((a, b) => {
    const aDate = new Date(a.node.date)
    const bDate = new Date(b.node.date)

    return bDate.getTime() - aDate.getTime()
  })

  const featuredPosts = sortedItems.filter((item) => item.node.featured)
  const sortedandFilteredItems = sortedItems.filter((item) => !item.node.featured)

  return (
    <Layout>
      <Halo {...data.allContentfulPageMetadata.edges[0].node} route={routes.contentCenter} />
      <Intro {...data.allContentfulPageMetadata.edges[0].node} />

      <section sx={{ bg: `white_slate8`, fontSize: 3 }}>
        <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, px: 4, py: 5 }}>
          <ul
            id="tags"
            ref={tagsRef}
            sx={{
              fontSize: 2,
              pl: 0,
              mt: -3,
              mb: 0,
              pt: 3,
              listStyleType: `none`,
            }}
          >
            <li
              sx={{
                mb: 3,
                mr: 3,
                display: `inline-block`,
              }}
            >
              <UniversalLink
                sx={{
                  display: `block`,
                  borderRadius: 6,
                  px: 3,
                  py: 1,
                  textDecoration: `none`,
                  bg: `primary`,
                  color: `white_black`,
                }}
                to={routes.contentCenter}
              >
                All
              </UniversalLink>
            </li>

            {tags.map(({ node }) => (
              <li
                key={node.name}
                sx={{
                  mb: 3,
                  mr: 3,
                  display: `inline-block`,
                }}
              >
                <UniversalLink
                  sx={{
                    display: `block`,
                    borderRadius: 6,
                    px: 3,
                    py: 1,
                    textDecoration: `none`,
                    bg: `gray0_slate9`,

                    [`:hover, :focus`]: {
                      bg: `gray1_black`,
                    },
                  }}
                  to={`${routes.contentCenter}/tags/${node.name.toLowerCase().replace(/\ /g, `-`)}`}
                >
                  {node.name}
                </UniversalLink>
              </li>
            ))}
          </ul>

          {!!featuredPosts.length && (
            <Fragment>
              <h1
                id="featured"
                sx={{ mt: 5, borderBottom: `1px solid`, borderBottomColor: `gray2_slate9`, pb: 2, fontSize: 3, mb: 0 }}
              >
                <UniversalLink
                  sx={{
                    color: `inherit`,
                    textDecoration: `none`,

                    [`:hover, :focus`]: {
                      textDecoration: `underline`,
                    },
                  }}
                  to={`${routes.contentCenter}#featured`}
                >
                  Featured
                </UniversalLink>
              </h1>

              <ul sx={{ listStyleType: `none`, pl: 0, mt: 4, mb: 0 }}>
                {featuredPosts.map((featuredPost) => {
                  return (
                    <BlogPostTeaser
                      key={featuredPost.node.title}
                      node={featuredPost.node}
                      featured
                      to={featuredPost.node.link || `${routes.blog}/${featuredPost.node.slug}`}
                    />
                  )
                })}
              </ul>
            </Fragment>
          )}

          <h1
            id="recent"
            sx={{
              mt: !!featuredPosts.length ? 6 : 5,
              borderBottom: `1px solid`,
              borderBottomColor: `gray2_slate9`,
              pb: 2,
              fontSize: 3,
              mb: 0,
            }}
          >
            <UniversalLink
              sx={{
                color: `inherit`,
                textDecoration: `none`,

                [`:hover, :focus`]: {
                  textDecoration: `underline`,
                },
              }}
              to={`${routes.contentCenter}#recent`}
            >
              Recent
            </UniversalLink>
          </h1>

          <ul
            sx={{
              pl: 0,
              mt: 4,
              mb: 0,
              listStyleType: `none`,
              display: [`block`, null, `grid`],
              gridTemplateColumns: `repeat(2, 1fr)`,
              columnGap: 4,
              rowGap: 5,
            }}
          >
            {sortedandFilteredItems.map(({ node }, index) => {
              let to = `#`

              if (node.caseStudySlug) to = `${routes.caseStudies}/${node.caseStudySlug}`
              if (node.blogPostSlug) to = `${routes.blog}/${node.blogPostSlug}`
              if (node.link) to = node.link

              return <BlogPostTeaserCard key={node.title} {...{ node, index, to }} />
            })}
          </ul>
        </div>
      </section>

      <SignUpCallToAction />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          author {
            company

            image {
              gatsbyImageData(height: 64, width: 64)
            }

            name
            slug
            title
          }

          blogPostSlug: slug
          date: publishDate

          description {
            childMarkdownRemark {
              html
            }
          }

          featured

          heroImage {
            gatsbyImageData(width: 936)
          }

          publishDate(formatString: "MMMM D, YYYY")
          slug
          tags
          title
        }
      }
    }

    allContentfulCaseStudy(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          author {
            company

            image {
              gatsbyImageData(height: 64, width: 64)
            }

            name
            slug
            title
          }

          caseStudySlug: slug
          date: publishDate

          description {
            childMarkdownRemark {
              html
            }
          }

          featured

          heroImage {
            gatsbyImageData(width: 936)
          }

          id
          publishDate(formatString: "MMMM D, YYYY")
          slug
          tags
          title
        }
      }
    }

    allContentfulExternalPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          author {
            company

            image {
              gatsbyImageData(height: 64, width: 64)
            }

            name
            slug
            title
          }

          date: publishDate

          description {
            childMarkdownRemark {
              html
            }
          }

          featured

          heroImage {
            gatsbyImageData(width: 936)
          }

          link
          publishDate(formatString: "MMMM D, YYYY")
          tags
          title
        }
      }
    }

    allContentfulPageMetadata(filter: { title: { eq: "Content center" } }) {
      edges {
        node {
          description
          title
        }
      }
    }

    allContentfulTag(sort: { fields: [name], order: ASC }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export default ContentCenter
