/** @jsx jsx */

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FC } from 'react'
import { jsx } from 'theme-ui'

import UniversalLink from '../components/universal-link'
import routes from '../constants/routes'

interface BlogPostTeaserProps {
  node: any
  index: number
  to: any
}

const BlogPostTeaser: FC<BlogPostTeaserProps> = ({ node, index, to }) => {
  const heroImage = getImage(node.heroImage)
  const authorImage = getImage(node?.author?.image)
  const isExternalPost = to.includes(`http`)
  let externalDomain = ``
  let href

  if (isExternalPost) {
    const { hostname } = new URL(to)
    externalDomain = hostname
    href = to
    to = undefined
  }

  return (
    <li key={node.id} sx={{ mt: index ? 5 : 0 }}>
      {node.tags?.map((tag, index) => (
        <UniversalLink
          key={tag}
          to={`${routes.contentCenter}/tags/${tag.toLowerCase().replace(/\ /g, `-`)}`}
          sx={{
            ml: index ? 3 : 0,
            display: `inline-block`,
            color: `primary`,
            textDecoration: `none`,
            fontWeight: 500,
            fontSize: 1,
            textTransform: `uppercase`,
            letterSpacing: `0.1em`,

            [`:hover, :focus`]: {
              textDecoration: `underline`,
            },
          }}
        >
          {tag}
        </UniversalLink>
      ))}

      {node.title && (
        <UniversalLink
          {...{ to, href }}
          sx={{
            color: `inherit`,
            textDecoration: `none`,
            display: `block`,

            [`:hover, :focus`]: {
              textDecoration: `underline`,
            },
          }}
        >
          <h1 sx={{ mt: 2, mb: 0 }}>{node.title}</h1>
        </UniversalLink>
      )}

      {node.author && (
        <div
          sx={{
            mt: 3,
            color: `inherit`,
          }}
        >
          <div sx={{ display: `flex`, alignItems: `center`, gap: 3 }}>
            <div
              sx={{
                borderRadius: 999,
                overflow: `hidden`,
                display: `inline-block`,
                backgroundImage: `var(--gradient)`,
                transform: `translateZ(0)`,

                [`&& img`]: {
                  my: 0,
                },
              }}
            >
              <GatsbyImage image={authorImage} alt={node.author.name} />
            </div>

            <div>
              <div sx={{ lineHeight: 1.25, fontWeight: 550 }}>{node.author.name}</div>
              <div sx={{ fontSize: 2, lineHeight: 1.25, fontStyle: `italic` }}>{node.author.title}</div>
              <div sx={{ fontSize: 2, lineHeight: 1.25 }}>{node.author.company}</div>
            </div>
          </div>
        </div>
      )}

      <UniversalLink
        {...{ to, href }}
        sx={{
          display: `block`,
          color: `inherit`,
          textDecoration: `none`,
        }}
      >
        {/* {node.publishDate && (
          <div
            sx={{
              mt: 3,
              fontSize: 2,
              color: `gray6_slate3`,
            }}
          >
            {node.publishDate}
          </div>
        )} */}

        <div
          sx={{
            aspectRatio: `16 / 9`,
            mt: 3,
            overflow: `hidden`,
            bg: `black`,
            backgroundImage: `var(--gradient)`,
            borderRadius: 6,
            boxShadow: `0 2px 8px -2px rgb(0 0 0 / 50%)`,
            transform: `translateZ(0)`,

            [`a:hover > &, a:focus > &`]: {
              boxShadow: `0 2px 32px rgb(0 0 0 / 25%)`,
            },
          }}
        >
          {heroImage && <GatsbyImage image={heroImage} alt="" sx={{ height: `100%`, objectFit: `cover` }} />}
        </div>

        {node.description && (
          <div
            sx={{ mt: 2, [`& > p`]: { my: 0 } }}
            dangerouslySetInnerHTML={{ __html: node.description.childMarkdownRemark.html }}
          />
        )}

        <div
          sx={{
            mt: 2,
            fontSize: 2,
            color: `primary`,

            [`a:hover > &, a:focus > &`]: {
              textDecoration: `underline`,
            },
          }}
        >
          Read more {isExternalPost ? `on ${externalDomain} »` : `»`}
        </div>
      </UniversalLink>
    </li>
  )
}

export default BlogPostTeaser
